import { FC, memo, useEffect, useImperativeHandle, useRef } from 'react'
import { useOutletContext } from '@remix-run/react'
import classNames from 'classnames'

import { gsap } from '~/imports/gsap.client'

import { PageHandle, PageProps, Strings } from '~/data/types'

import copy from '~/utils/copy'

import css from '#/css/components/PageFallback/PageFallback.module.css'

import ShopifyPartnersLogo from '#/svgs/ShopifyPartnersLogo'

export interface PageFallbackProps extends PageProps {
  strings: Strings['pagePrivate']['body']
}

export interface ViewProps extends PageFallbackProps {}

// View (pure and testable component, receives props from the controller)
export const View: FC<ViewProps> = ({ strings, onReady }) => {
  const rootRef = useRef<HTMLElement>(null)
  const handleRef = useRef<PageHandle>(null)

  useEffect(() => {
    onReady?.(handleRef)
  }, [onReady])

  useImperativeHandle(handleRef, () => ({
    animateIn: () => gsap.timeline().to(rootRef.current, { opacity: 1 }),
    animateOut: () => gsap.timeline().to(rootRef.current, { opacity: 0 })
  }))

  return (
    <main className={classNames('PageFallback', css.root)} ref={rootRef}>
      <ShopifyPartnersLogo className={css.logo} />

      <div className={css.content}>
        <h1 className={css.title} {...copy.html(strings.title)} />
        <p className={css.description} {...copy.html(strings.description)} />
      </div>
    </main>
  )
}

View.displayName = 'PageFallback-View'

// Controller (handles global state, router, data fetching, etc. Feeds props to the view component)
const PageFallback: FC<PageFallbackProps> = (props) => {
  const context = useOutletContext() as PageProps
  return <View {...props} {...context} />
}

export default memo(PageFallback)
